import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, catchError, of } from 'rxjs';
import { DropdownModel } from '../core';
import { AssignUsers } from '../core/models/assign-users.model';

@Injectable({ providedIn: 'root' })
export class RoleService extends ApiService {
  constructor(http: HttpClient) {
    super(http, 'Role');
  }

  public getUsersMultiselect(): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(`${this.route()}/GetMultiselectUsers`).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          return of([]);
        } else {
          throw error;
        }
      })
    );
  }

  public assignUsers(assignUsersModel: AssignUsers) {
    return this.http.post<DropdownModel[]>(`${this.route()}/AssignUsers`, assignUsersModel);
  }
}
